.gameTable {
  background: linear-gradient(145deg, #1b4332 0%, #2d6a4f 100%);
  background-image: 
    radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.2) 100%),
    repeating-linear-gradient(45deg, 
      rgba(255,255,255,0.03) 0px, 
      rgba(255,255,255,0.03) 2px,
      transparent 2px, 
      transparent 4px
    );
  box-shadow:
    0 0 40px rgba(0,0,0,0.3),
    inset 0 0 60px rgba(0,0,0,0.4),
    inset -24px -24px 0 #8b6b4f,
    inset 24px 24px 0 #8b6b4f,
    inset 0 0 10px rgba(255,255,255,0.1);
  border-radius: 12px;
}

.table {
  background: linear-gradient(145deg, #2d6a4f 0%, #1b4332 100%);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2), inset 0 2px 4px rgba(255,255,255,0.1);
  border: 2px solid #40916c;
  border-radius: 12px;
  transition: transform 0.2s ease;
}

.cardPlaceholder {
  border: 2px dashed rgba(255,255,255,0.3);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.tightInfo {
  background: rgba(255,255,255,0.1);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  padding: 4px 8px;
  color: white;
  text-shadow: 0 1px 2px rgba(0,0,0,0.3);
  cursor: default;
}

.waitingIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,0.7);
  color: white;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 1.2em;
  animation: pulse 1.5s infinite;
  cursor: default;
}

.nextRoundButton {
  padding: 12px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}

.rank-display {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  transition: filter 0.3s ease;
  cursor: default;
}

.rank-daifugo { background: linear-gradient(45deg, #FFD700, #FFA500); }
.rank-fugo { background: linear-gradient(45deg, #C0C0C0, #A9A9A9); }
.rank-hinmin { background: linear-gradient(45deg, #FF4D4D, #CC0000); }
.rank-daihinmin { background: linear-gradient(45deg, #990000, #660000); }

.rank-display:hover {
  animation: shimmer 2s infinite;
}

.rank-text {
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  white-space: nowrap;
}

.playerNameContainer {
  background: linear-gradient(135deg, rgba(121,85,72,0.85), rgba(188,134,107,0.85));
  padding: 4px 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  border: 1px solid rgba(255,255,255,0.1);
  z-index: 25;
}

.playerName {
  color: rgba(255,255,255,0.95);
  font-weight: 700;
  font-size: 14px;
  text-shadow: 0 1px 1px rgba(0,0,0,0.25);
}

.rankBadgeSmall {
  padding: 3px 12px;
  clip-path: polygon(15% 0, 85% 0, 100% 50%, 85% 100%, 15% 100%, 0 50%);
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  min-width: 24px;
  text-align: center;
  margin-left: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  border: 1px solid rgba(255,255,255,0.2);
}

.rankDaifugo { background: linear-gradient(45deg, #FFD700, #FFA500); }
.rankFugo { background: linear-gradient(45deg, #C0C0C0, #A9A9A9); }
.rankHinmin { background: linear-gradient(45deg, #FF4D4D, #CC0000); }
.rankDaihinmin { background: linear-gradient(45deg, #990000, #660000); }

@keyframes shimmer {
  0% { filter: brightness(1); }
  50% { filter: brightness(1.3); }
  100% { filter: brightness(1); }
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}