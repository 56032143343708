.game-modes-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-modes-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  overflow-x: auto;
  max-width: 100%;
  align-items: center;
  -ms-overflow-style: none;  /* Hide scrollbar IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar Firefox */
}

/* Hide scrollbar for Chrome/Safari */
.game-modes-container::-webkit-scrollbar {
  display: none;
}

.game-mode {
  flex: 0 0 auto;
  width: calc(100vh / 3);
  height: calc(100vh / 3);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.game-mode:hover {
  transform: scale(1.05);
}

.game-mode-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.game-mode-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
}