.scoreBoardContainer {
  z-index: 100;
}

.trigger {
  background: linear-gradient(to right, #f0ead6, #fff5e6);
  width: var(--board-width-px);
  padding: calc(0.03 * var(--board-width-px)) calc(0.06 * var(--board-width-px));
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 3px 7px rgba(0,0,0,0.2);
  font-family: 'Courier New', monospace;
  color: #2c3e50;
}

.scoreBoard {
  --board-width: 100%;
  --board-height: 100%;
  --base-fs: calc(var(--board-height-px) * 0.08);
  --line-height: calc(var(--base-fs) * 1.15); /* Explicit line height */
  position: absolute;
  bottom: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px); /* Start position - slightly overlapping trigger */
  transition: 
    opacity 0.3s ease-out,
    visibility 0.3s ease-out,
    transform 0.3s ease-out;
  background: linear-gradient(to right, #f0ead6, #fff5e6);
  border-radius: calc(0.03 * var(--board-width-px));
  box-shadow: 
    2px 3px 7px rgba(0,0,0,0.2),
    0 0 40px rgba(255,255,255,0.1);
  font-family: 'Courier New', monospace;
  padding: 0 calc(0.06 * var(--board-width-px));
  overflow-y: auto;
}

.scoreBoardContainer:hover .scoreBoard {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.title {
  font-size: calc(1.5 * var(--base-fs));
  line-height: calc(1.5 * var(--line-height));
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
}


.scrollContainer {
  width: 100%;
  height: calc(0.8 * var(--board-height));
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
}

.headers {
  display: flex;
  gap: calc(0.03 * var(--board-width-px));
  border-bottom: 2px solid #91d1d3;
  position: sticky;
  top: 0;
  left: 0;
  margin-bottom: calc(0.03 * var(--board-width-px));
  background: linear-gradient(to right, #f0ead6, #fff5e6); /* Match parent gradient */
  z-index: 2;
  white-space: nowrap;
  min-width: fit-content;
}

.roundNumber {
  color: #666;
  width: calc(0.1 * var(--board-width-px));
}

.playerName {
  width: calc(0.2 * var(--board-width-px));
  text-align: center;
  font-weight: bold;
  color: #2c3e50;
  overflow: hidden;
  white-space: nowrap;
}

.scores {
  font-size: var(--base-fs);
  line-height: var(--line-height);
  position: relative;
  min-width: fit-content;
}

.scores::after {
  content: '';
  position: absolute;
  top: 0;
  left: calc(0.1 * var(--board-width-px));
  right: calc(0.1 * var(--board-width-px));
  height: 100%;
  background: repeating-linear-gradient(
    transparent 0px,
    transparent calc(var(--line-height) - 1px),    /* Match text height */
    #91d1d3 calc(var(--line-height)),  /* Line after text */
    transparent calc(var(--line-height))
  );
  z-index: 0;
  pointer-events: none;
}

.round {
  display: flex;
  gap: calc(0.03 * var(--board-width-px));
  align-items: center;
  position: relative;
  z-index: 1;
}

.score {
  min-width: calc(0.2 * var(--board-width-px));
  text-align: center;
  font-weight: bold;
  color: #2c3e50;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.round:last-child {
  animation: slideIn 0.3s ease-out;
}