.gameTable {
  background: linear-gradient(145deg, #1b4332 0%, #2d6a4f 100%);
  background-image: 
    radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.2) 100%),
    repeating-linear-gradient(45deg, 
      rgba(255,255,255,0.03) 0px, 
      rgba(255,255,255,0.03) 2px,
      transparent 2px, 
      transparent 4px
    );
  box-shadow:
    0 0 40px rgba(0,0,0,0.3),
    inset 0 0 60px rgba(0,0,0,0.4),
    inset -12px -12px 0 #8b6b4f,
    inset 12px 12px 0 #8b6b4f,
    inset 0 0 10px rgba(255,255,255,0.1);
  border-radius: 12px;
}